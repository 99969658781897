/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
	margin: 0;
	padding: 0;
	box-sizing: boder-box;
    font-family: 'Open Sans', sans-serif;
}

:root{
	scroll-behavior: smooth;
}